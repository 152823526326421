import React, { useCallback } from "react";

const SkipToContent = ({to}) => {
  const onKeyDown = useCallback((e) => {
    if (e.key === "Escape") {
      e.target.blur();    
    }
  }, []);
  return (
    <a
      role="button"
      onKeyDown={onKeyDown}
      className="absolute left-1/2 -translate-x-1/2 -translate-y-full focus:translate-y-0 bg-gray-400 focus:outline focus:outline-gray-600 p-4 z-10"
      href={to}
    >
      Skip to content
    </a>
  );
};

export {
  SkipToContent,
};
