import { useStaticQuery, graphql } from "gatsby";

const useSiteMetadata = () => {
  const {site: {siteMetadata}} = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteTitle
          siteDescription
          siteUrl
          getAQuoteUrl
          mailingListUrl
          facebookUrl
          instagramUrl
          pinterestUrl
          tiktokUrl
        }
      }
    }
  `);
  return siteMetadata;
}

export {
  useSiteMetadata,
};