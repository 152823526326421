import React from "react";

import { Alert } from "./Alert";
import { NavBar } from "./NavBar";
import { SkipToContent } from "./SkipToContent";
import { SiteSeo } from "./Seo";
import { Footer } from "./Footer";
import { CookieAlert } from "./CookieAlert";
import { Link } from "gatsby";

const Layout = ({ children }) => {
  return (
    <>
      <SiteSeo />
      <SkipToContent to="#main" />
      {/* <Alert>
        20% off all portraits for a limited time, <Link className="underline" to="/portraits">click here</Link>
      </Alert> */}
      <CookieAlert />
      <NavBar />
      <main id="main">
        {children}
      </main>
      <Footer />
    </>
  );
};

export {
  Layout,
};
