import React from "react";
import classNames from "classnames";

const Heading = ({as: Component = "h1", className, children, ...props}) => {
  return (
    <Component className={classNames("text-4xl mb-10 text-center", className)} {...props}>
      {children}
    </Component>
  );
};

const TagLine = ({as: Component = "p", className, children, ...props}) => {
  return (
    <Component className={classNames("-mt-5 text-lg text-center", className)} {...props}>
      {children}
    </Component>
  );
};

const Container  = ({as: Component = "div", className, children, ...props}) => {
  return (
    <Component className={classNames("container px-4 mx-auto", className)} {...props}>
      {children}
    </Component>
  );
};

const Section = ({as: Component = "section", className, children, ...props}) => {
  return (
    <Component className={classNames("mx-auto my-12", className)} {...props}>
      {children}
    </Component>
  );
};

export {
  Heading,
  TagLine,
  Container,
  Section,
};
