import React, { useCallback, useRef, useState } from "react";
import { Link } from "gatsby";
import { StaticImage  } from "gatsby-plugin-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faBars, faXmark, faSquare } from "@fortawesome/free-solid-svg-icons";
import { faFacebookSquare, faInstagramSquare, faPinterestSquare, faTiktok } from "@fortawesome/free-brands-svg-icons"
import classNames from "classnames";

import { useSiteMetadata } from "../hooks/SiteMetadataQuery";
import { Container } from "../components/Page";

const NavBar = () => {
  const {siteTitle, getAQuoteUrl, facebookUrl, instagramUrl, pinterestUrl, tiktokUrl } = useSiteMetadata();
  const [mobileMenu, setMobileMenu] = useState();
  const onMobileMenuClick = useCallback(() => {
    setMobileMenu(x => !x);
  }, []);
  return (
    <Container as="nav" className="py-4 flex flex-wrap">
      <Link className="order-1" to="/">
        <StaticImage
          src="../images/logo.jpg"
          alt={siteTitle}
          layout="fixed"
          height={80}
          placeholder="none"
        />
      </Link>      
      <div className="md:hidden w-full h-0 order-4"></div>{/*  Forces new line in flex box */}
      <div className={classNames("grow md:flex items-center justify-end text-lg font-headings mt-2 md:mt-0 md:text-black md:bg-transparent order-5 md:order-2", {"hidden": !mobileMenu})}>
        <NavBarItem dropdownIdSuffix={0} label={() => "Weddings"} items={[
          {
            label: () => "House Collection",
            to: "/weddings/house-collection",
          },
          {
            label: () => "Custom Designs",
            to: "/weddings/custom",
          },
          {
            label: () => "On The Day",
            to: "/weddings/on-the-day",
          },
          {
            label: () => "Finishing Touches",
            to: "/weddings/finishing-touches",
          },
          {
            external: true,
            label: () => "Get A Quote",
            to: getAQuoteUrl,
          },
        ]} />
        <NavBarItem label={() => "Portaits"} to="/portraits" />
        <NavBarItem label={() => "About"} to="/about" />
        <NavBarItem label={() => "Contact"} to="/contact" />
      </div>
      <div className="grow md:grow-0 flex items-center justify-end text-lg font-headings order-2 md:order-3 mr-4 md:mr-0">
        <SocialItem description="Facebook" icon={<FacebookSquare />} url={facebookUrl} />
        <SocialItem description="Instagram" icon={<InstagramSquare />} url={instagramUrl} />
        <SocialItem description="Pinterest" icon={<PinterestSquare />} url={pinterestUrl} />
        <SocialItem description="TikTok" icon={<TiktokSquare />} url={tiktokUrl} />
      </div>
      <div className="flex items-center text-xl font-headings md:hidden order-3">
        <button className="border border-gray-300 p-2" onClick={onMobileMenuClick}>
          <span className="not-sr-only"><FontAwesomeIcon fixedWidth icon={mobileMenu ? faXmark : faBars} /></span>
          <span className="sr-only">{mobileMenu ? "Menu" : "Close"}</span>
        </button>
      </div>
    </Container>
  );
};

const NavBarItem = ({label, to, external, items, dropdownIdSuffix}) => {
  const className = "inline-block hover:underline px-1 md:px-3 lg:px-4 py-2";
  return (
    <div className="text-center md:text-left">
      {to && <LinkOrAnchor
        external={external}
        label={label}
        to={to}
        className={className} />}
      {!to && <NavDropdown
        toggleLabel={label}
        items={items}
        buttonId={"navbar-item-" + dropdownIdSuffix}
        buttonClassName={className}
      />}
    </div>
  );
};

const NavDropdown = ({ buttonId, buttonClassName, toggleLabel: ToggleLabel, items }) => {
  const containerRef = useRef();
  const [toggled, setToggled] = useState(false);
  const onClick = useCallback(() => {
    setToggled(t => !t);
  }, []);
  const onBlur = useCallback((e) => {
    if (!containerRef.current.contains(e.relatedTarget)) {
      setToggled(false);
    }
  }, []);
  const onKeyDown = useCallback((e) => {
    if (e.key === "Escape") {
      setToggled(false);
    }
  }, []);

  return (
    <>
      <div role="presentation" ref={containerRef} className="relative" tabIndex={-1} onBlur={onBlur} onKeyDown={onKeyDown}>
        <button id={buttonId} className={buttonClassName} onClick={onClick} aria-haspopup="true" aria-expanded={toggled}>
          <ToggleLabel />{" "}<span className="not-sr-only"><FontAwesomeIcon className=" text-gray-500" icon={faCaretDown} /></span>
        </button>
        {toggled &&
          <div aria-labelledby={buttonId} className="md:absolute flex flex-col top-full md:right-0 mx-4 md:mx-0 bg-white border-y border-gray-300 md:border z-50">
            {items.map((item, i) => <NavDropdownItem onBlur={onBlur} {...item} key={i} />)}
          </div>}
      </div>
      {/* Prevents clicks to other elements while menu is open */}
      {toggled && <div className="md:fixed md:z-40 md:inset-0"></div>}
    </>
  );
};

const NavDropdownItem = ({ onBlur, label, to, external }) => {
  const onMouseDown = useCallback((e) => {
    e.preventDefault();
  }, []);
  return (
    <LinkOrAnchor
      external={external}
      to={to}
      label={label}
      onBlur={onBlur}
      onMouseDown={onMouseDown}
      className="hover:underline px-4 py-2 whitespace-nowrap"
    />
  );
};

const SocialItem = ({description, icon, url}) => {
  return (
    <NavBarItem 
      external
      label={() =>
        <>
          <span className="not-sr-only text-gray-500 hover:text-gray-900 transition-colors duration-300 text-3xl md:text-2xl">{icon}</span>
          <span className="sr-only">{description}</span>
        </>
      }
      to={url}
    />
  );
};

const LinkOrAnchor = ({ external, to, label: Label, ...props }) => {
  if (external) {
    return (
      <a {...props} href={to} rel="noreferrer" target="_blank"><Label /></a>
    );
  }
  return (
    <Link activeClassName="font-bold" {...props} to={to}><Label /></Link>
  );
};

const FacebookSquare = () => {
  return (
    <FontAwesomeIcon fixedWidth icon={faFacebookSquare} />
  );
};

const InstagramSquare = () => {
  return (
    <FontAwesomeIcon fixedWidth icon={faInstagramSquare} />
  );
};

const PinterestSquare = () => {
  return (
    <FontAwesomeIcon fixedWidth icon={faPinterestSquare} />
  );
};

const TiktokSquare = () => {
  return (
    <span className="fa-layers fa-fw">
      <FontAwesomeIcon fixedWidth icon={faSquare} />
      <FontAwesomeIcon fixedWidth color="white" size="xs" icon={faTiktok} />
    </span>
  );
};

export {
  NavBar,
};
