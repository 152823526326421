import React, { useMemo } from "react";
import { Link } from "gatsby";

import { useSiteMetadata } from "../hooks/SiteMetadataQuery";

const Footer = () => {
  const year = useMemo(() => new Date().getFullYear(), []);
  const {siteTitle, mailingListUrl} = useSiteMetadata();
  return (
    <FooterContainer>
      <div>
        <FooterLink as={Link} to="/policies/privacy">Privacy Policy</FooterLink>
        <FooterLink as={Link} to="/policies/wedding-stationery">Wedding Stationery Policy</FooterLink>
        {mailingListUrl && <FooterLink as="a" target="_blank" rel="noreferrer" href={mailingListUrl}>
          Sign up to mailing list
        </FooterLink>}
      </div>
      <div className="mt-6 md:mt-0">&#169; {year}, {siteTitle}</div>
    </FooterContainer>
  );
};

const FooterContainer = ({children}) => {
  return (
    <footer className="mt-6 text-center text-gray-500 md:text-left w-full p-4 md:flex md:items-center md:justify-between border-t border-gray-200 text-sm">
      {children}
    </footer>
  );
};

const FooterLink = ({as: Component, children, ...props}) => {
  return (
    <Component className="block md:inline-block mb-2 md:mb-0 md:mr-6 hover:underline" {...props}>
      {children}
    </Component>
  );
};

export {
  Footer,
};
